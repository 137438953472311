import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import "../category-page.css";
import _ from "lodash";
import { PageNotFound, PageTemplate } from "./page-template";

const TagsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const tag_name = location.pathname.split("/tags/")[1];
  const posts = data.allMdx.nodes.filter(
    (node) => node.frontmatter.tags && node.frontmatter.tags.includes(tag_name)
  );
  if (posts.length === 0) {
    return <PageNotFound location={location} title={siteTitle} />;
  }
  return (
    <PageTemplate
      location={location}
      title={siteTitle}
      page_title={tag_name}
      posts={posts}
    />
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          tags
          thumbnail {
            id
            absolutePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
